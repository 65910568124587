import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { AvailableBonusResp, Bonus, QueryParams } from './interfaces'
import {
  ResponseData,
  FormError,
  defaultData,
  ValidatorParams
} from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'bonus',
  stateFactory: true,
  namespaced: true
})
export default class BonusModule extends VuexModule {
  /**
   * * Массив бонусов и пагинатор
   */
  bonusesValue: ResponseData<Bonus> = defaultData

  /**
   * ! Необходимо уточнить дефолтные значения
   */
  /**
   * * Бонус
   */
  bonusValue: Bonus = {
    name: '',
    bonusTypeId: 1,
    bonusAccountId: 1,
    value: 0,
    percent: 0,
    activationTime: 0,
    lifetime: null,
    params: {},
    userTypeId: null
  }

  // ? ______________ getters ______________

  /**
   * * Получить массив бонусов и пагинатор
   */
  get bonuses (): ResponseData<Bonus> {
    return this.bonusesValue
  }

  /**
   * * Получить бонус по id
   */
  get bonusById () {
    const bonuses = this.bonusesValue.data
    return function (id: number): Bonus | undefined {
      return bonuses.find(bonus => bonus.id === id)
    }
  }

  /**
   * * Получить бонус
   */
  get bonus (): Bonus {
    return this.bonusValue
  }

  get bonusValidators (): ValidatorParams {
    return {
      name: [
        {
          type: 'string',
          required: true,
          trigger: ['blur', 'change'],
          message: 'Введите название бонуса'
        }
      ],
      bonusTypeId: [
        {
          type: 'number',
          required: true,
          trigger: ['blur'],
          message: 'Выберите тип бонуса'
        }
      ],
      bonusAccountId: [
        {
          type: 'number',
          required: true,
          trigger: ['blur'],
          message: 'Выберите бонусный аккаунт'
        }
      ],
      value: [
        {
          required: false,
          type: 'number',
          trigger: ['blur', 'change'],
          message: 'Введите кол-во бонусов (фиксированное)'
        }
      ],
      percent: [
        {
          required: false,
          type: 'number',
          min: 0,
          max: 100,
          trigger: ['blur', 'change'],
          message: 'Введите кол-во бонусов (в процентах)'
        }
      ],
      activationTime: [
        {
          required: true,
          type: 'number',
          trigger: ['blur', 'change'],
          message: 'Введите время активации бонуса (в днях)'
        }
      ],
      // lifetime: [{
      //   required: true,
      //   type: 'number',
      //   trigger: ['blur', 'change'],
      //   message: 'Введите время действия бонуса (в днях)'
      // }],
      limit: [
        {
          required: true,
          type: 'number',
          trigger: ['blur', 'change'],
          message: 'Введите сумму'
        }
      ],
      period: [
        {
          required: true,
          type: 'number',
          min: 1,
          trigger: ['blur', 'change'],
          message: 'Введите период'
        }
      ],
      statuses: [
        {
          required: true,
          type: 'array',
          min: 1,
          trigger: ['blur', 'change'],
          message: 'Выберите статусы товара (минимум 1)'
        }
      ]
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить массив бонусов
   * @param bonuses - массив бонусов и пагинатор
   */
  @Mutation
  setBonuses (bonuses: ResponseData<Bonus>) {
    this.bonusesValue = bonuses
  }

  /**
   * * Сбросить массив бонусов и пагинатор
   */
  @Mutation
  resetBonuses (): void {
    this.bonusesValue = defaultData
  }

  /**
   * * Установить бонус
   * @param bonus - бонус
   */
  @Mutation
  setBonus (bonus: Bonus) {
    this.bonusValue = bonus
  }

  /**
   * * Сбросить бонус
   */
  @Mutation
  resetBonus (): void {
    this.bonusValue = {
      name: '',
      bonusTypeId: 1,
      bonusAccountId: 1,
      value: 0,
      percent: 0,
      activationTime: 0,
      lifetime: null,
      params: {},
      userTypeId: null
    }
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка бонусов
   * @param queryParams - параметры пагинации
   * @returns массив бонусов и пагинатор
   */
  @Action({
    rawError: true,
    commit: 'setBonuses'
  })
  async getBonuses (
    queryParams: QueryParams | null = null
  ): Promise<ResponseData<Bonus>> {
    try {
      const { data } = await $axios.get('/bonus', {
        params: { ...queryParams }
      })
      const response: ResponseData<Bonus> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание бонуса
   * @returns бонус
   */
  @Action({
    rawError: true
  })
  async createBonus (): Promise<Bonus> {
    try {
      const {
        data: { data }
      } = await $axios.post('/bonus', this.bonus)
      const response: Bonus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение бонуса по id
   * @param id - id бонуса
   * @returns бонус
   */
  @Action({
    rawError: true,
    commit: 'setBonus'
  })
  async getBonusById (id: number): Promise<Bonus> {
    try {
      const {
        data: { data }
      } = await $axios.get(`/bonus/${id}`)
      const response: Bonus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение бонуса
   * @returns бонус
   */
  @Action({
    rawError: true
  })
  async editBonus (): Promise<Bonus> {
    try {
      const { id, ...bonus } = this.bonus
      const {
        data: { data }
      } = await $axios.put(`/bonus/${id}`, bonus)
      const response: Bonus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }

  /**
   * * Запрос на удаление бонуса
   * @param id - id бонуса
   * @returns бонус
   */
  @Action({
    rawError: true
  })
  async removeBonus (id: number): Promise<Bonus> {
    try {
      const {
        data: { data }
      } = await $axios.delete(`/bonus/${id}`)
      const response: Bonus = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async getAvailableForOrder ({
    siteApiUrl,
    orderId
  }: {
    siteApiUrl: string;
    orderId: number;
  }) {
    try {
      const { data } = await $axios.get(
        `${siteApiUrl}/bonuses/v1/available-for-order?orderId=${orderId}`,
        {
          headers: {
            Authorization: ''
          }
        }
      )
      return data as AvailableBonusResp
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
