import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Collection } from './interfaces'
import { defaultData, FormError, ResponseData, PageParams, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'collections',
  stateFactory: true,
  namespaced: true
})

export default class collectionsModule extends VuexModule {
  /**
   * * Массив коллекций и пагинатор
   */
  collectionsValue: ResponseData<Collection> = defaultData
  /**
   * * Объект коллекции
   */
  collectionValue: Collection = {
    name: '',
    code: null,
    sort: 0,
    fileId: null,
    previewId: null,
    mobileFileId: null,
    mobilePreviewId: null,
    active: true,
    params: {
      filterOptionValueId: null,
      sortOptionValues: null,
      carouselSortOptionId: null,
      carouselSortOptionValues: null
    },
    filterOptionId: null,
    sortOptionId: null,
    description: ''
  }

  // ? ____________ getters ____________

  /**
   * * Получить массив коллекций
   */
  get collections (): ResponseData<Collection> {
    return this.collectionsValue
  }

  get collection (): Collection {
    return this.collectionValue
  }

  /**
   * * Получить коллекцию по id
   */
  get collectionById () {
    return function (id: number): Collection | undefined {
      return [...this.collections.data].find((collection: Collection) => collection.id === id)
    }
  }

  get collectionValidators (): ValidatorParams {
    return {
      name: [{ required: true, message: 'Введите название коллекции', trigger: 'blur' }],
      sort: [{ required: true, message: 'Введите целое число', trigger: ['blur'], pattern: validatorsPattern.wholeNumbers }],
      params: {
        filterOptionValueId: [{ required: true, message: 'Выберите значение', trigger: ['blur', 'change'] }],
        sortOptionValues: [{ required: true, type: 'array', min: 1, message: 'Выберите значения', trigger: ['blur', 'change'] }]
      }
    }
  }

  // ? ____________ setters ____________

  /**
   * * Установить массив коллекций
   * @param collections - массив коллекций и пагинатор
   */
  @Mutation
  setCollections (collections: ResponseData<Collection>) {
    this.collectionsValue = collections
  }

  /**
   * * Установить коллекцию
   * @param collection - объект коллекции
   */
  @Mutation
  setCollection (collection: Collection) {
    this.collectionValue = collection
  }

  /**
   * * Сбросить массив коллекций и пагинатор
   */
  @Mutation
  resetCollections () {
    this.collectionsValue = defaultData
  }

  /**
   * * Сбросить объект коллекции
   */
  @Mutation
  resetCollection () {
    this.collectionValue = {
      name: '',
      code: null,
      sort: 0,
      fileId: null,
      previewId: null,
      mobileFileId: null,
      mobilePreviewId: null,
      active: true,
      params: {
        filterOptionValueId: null,
        sortOptionValues: null,
        carouselSortOptionId: null,
        carouselSortOptionValues: null
      },
      filterOptionId: null,
      sortOptionId: null,
      description: ''
    }
  }

  // ? ____________ actions ____________

  /**
   * * Получение списка коллекций
   * @param pageParams - параметры запроса
   * @returns массив коллекций и пагинатор
   */
  @Action({
    rawError: true,
    commit: 'setCollections'
  })
  async getCollections (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/shop/collection', { params: pageParams })
      return data as ResponseData<Collection>
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }

  @Action({
    rawError: true,
    commit: 'setCollection'
  })
  async getCollectionById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/collection/${id}`)
      return data as Collection
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }

  /**
   * * Создание коллекции
   * @returns объект коллекции
   */
  @Action({
    rawError: true
  })
  async createCollection () {
    try {
      const newCollection = {
        name: this.collectionValue.name,
        code: this.collectionValue.code,
        sort: this.collectionValue.sort,
        fileId: this.collectionValue.fileId,
        previewId: this.collectionValue.previewId,
        mobileFileId: this.collectionValue.mobileFileId,
        mobilePreviewId: this.collectionValue.mobilePreviewId,
        active: this.collectionValue.active,
        params: this.collectionValue.params,
        filterOptionId: this.collectionValue.filterOptionId,
        sortOptionId: this.collectionValue.sortOptionId,
        description: this.collectionValue.description
      }
      const { data: { data } } = await $axios.post('/shop/collection', newCollection)
      return data as Collection
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }

  /**
   * * Изменение коллекции
   * @param id - id коллекции
   * @returns объект коллекции
   */
  @Action({
    rawError: true
  })
  async editCollection () {
    try {
      const newCollection = {
        name: this.collectionValue.name,
        code: this.collectionValue.code,
        sort: this.collectionValue.sort,
        fileId: this.collectionValue.fileId,
        previewId: this.collectionValue.previewId,
        mobileFileId: this.collectionValue.mobileFileId,
        mobilePreviewId: this.collectionValue.mobilePreviewId,
        active: this.collectionValue.active,
        params: this.collectionValue.params,
        filterOptionId: this.collectionValue.filterOptionId,
        sortOptionId: this.collectionValue.sortOptionId,
        description: this.collectionValue.description
      }
      const { data: { data } } = await $axios.put(`/shop/collection/${this.collectionValue.id}`, newCollection)
      return data as Collection
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }

  /**
   * * Генерация коллекции по опции
   * @param optionId - id опции
   * @returns -объект коллекции
   */
  @Action({
    rawError: true
  })
  async generateCollection (optionId: number) {
    try {
      const { data: { data } } = await $axios.post('/shop/collection/generate', { optionId })
      return data as Collection
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }

  /**
   * * Удаление коллекции
   * @param id - id коллекции
   * @returns объекут коллекции
   */
  @Action({
    rawError: true
  })
  async removeCollection (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/shop/collection/${id}`)
      return data as Collection
    } catch (error) {
      throw new FormError(error as AxiosError)
    }
  }
}
