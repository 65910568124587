import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, ValidatorParams, ResponseData } from '../../interfaces'
import { OrderOfferData, OrderOffer, Ids, Params, Filters } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'orderOffers',
  stateFactory: true,
  namespaced: true
})
export default class OrderOffersModule extends VuexModule {
  filtersValue: Filters = {
    sort: undefined,
    order: undefined
  }

  orderOffersValue: ResponseData<OrderOffer> = defaultData

  orderOfferDataValue: OrderOfferData = {
    orderId: 0,
    offerId: undefined,
    margin: 0,
    count: 0
  }

  // ? ------ getters ------ //

  get filters (): Filters {
    return this.filtersValue
  }

  get orderOffers (): ResponseData<OrderOffer> {
    return this.orderOffersValue
  }

  get orderOfferData (): OrderOfferData {
    return this.orderOfferDataValue
  }

  get validators (): ValidatorParams {
    return {
      offerId: [{
        required: true, type: 'number', message: 'Выберите торговое предложение', trigger: ['change']
      }],
      margin: [{
        required: true, type: 'number', message: 'Введите поправку', trigger: ['change', 'blur']
      }],
      count: [{
        required: true, type: 'number', min: 1, message: 'Введите кол-во', trigger: ['change', 'blur']
      }]
    }
  }

  // ? ------ setters ------ //

  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  @Mutation
  resetFilters () {
    this.filtersValue = {
      sort: undefined,
      order: undefined
    }
  }

  @Mutation
  setOrderOffers (orderOffers: ResponseData<OrderOffer>) {
    this.orderOffersValue = orderOffers
  }

  @Mutation
  setOrderOfferData (orderOfferData: OrderOfferData) {
    this.orderOfferDataValue = orderOfferData
  }

  @Mutation
  resetOrderOfferData () {
    this.orderOfferDataValue = {
      orderId: 0,
      offerId: undefined,
      margin: 0,
      count: 0
    }
  }

  @Mutation
  resetOrderOffers () {
    this.orderOffersValue = defaultData
  }

  // ? ------ actions ------ //

  @Action({
    rawError: true,
    commit: 'setOrderOffers'
  })
  async getOrderOffers (params: Params): Promise<ResponseData<OrderOffer>> {
    try {
      const { data } = await $axios.get(`/shop/orders/${params.orderId}/offers`, { params: { page: params.page, pageSize: params.pageSize, isBlank: params.isBlank, ...this.filters } })
      const response: ResponseData<OrderOffer> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async createOrderOffer (siteApiUrl: string): Promise<Partial<OrderOfferData>> {
    try {
      const { orderId, offerId, margin, count } = this.orderOfferData
      await $axios.put(`${siteApiUrl}/orders/v1/${orderId}/offers`, { offerId, margin, count }, {
        headers: {
          common: {
            Authorization: ''
          }
        }
      })
      return { offerId, margin, count }
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async editOrderOffer (siteApiUrl: string): Promise<Partial<OrderOfferData>> {
    try {
      const { orderId, offerId, margin, count } = this.orderOfferData
      await $axios.put(`${siteApiUrl}/orders/v1/${orderId}/offers`, { offerId, margin, count }, {
        headers: {
          common: {
            Authorization: ''
          }
        }
      })
      return { offerId, margin, count }
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async removeOrderOffer ({ ids, siteApiUrl }: { ids: Ids, siteApiUrl: string }): Promise<Ids> {
    try {
      await $axios.put(`${siteApiUrl}/orders/v1/${ids.orderId}/offers`, { offerId: ids.offerId, margin: 0, count: 0 })
      return ids
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Выгрузить в excel
   */
  @Action({
    rawError: true
  })
  async exportExcel (orderId: number) {
    try {
      const { data } = await $axios.get(`/shop/orders/${orderId}/offers/excel`, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
