import { VueWaitInstance } from 'vue-wait'
import { ActionTree } from 'vuex'
import { Context } from '@nuxt/types'
import { NuxtCookies } from 'cookie-universal-nuxt'
import { ColorModeInstance } from '@nuxtjs/color-mode/types/color-mode'
import { CalculationsPlugin } from '~/store/modules/calculations/interfaces'
import { DadataPlugin } from '~/store/modules/dadata/interface'
import EventTriggersPlugin from '~/store/modules/event-triggers/interfaces'
import { PageTemplatesPlugin } from '~/store/modules/page-templates/interfaces'
// import NewslettersPlugin from '~/store/modules/newsletters/store'
import { CalculatorConditionsPlugin } from '~/store/modules/calculator-conditions/interfaces'
import { CalculatorElementsPlugin } from '~/store/modules/calculator-elements/interfaces'
import { CalculatorTypesPlugin } from '~/store/modules/calculator-types/interfaces'
import { CalculatorsPlugin } from '~/store/modules/calculators/interfaces'
import { AuthPlugin } from '~/store/modules/auth/interfaces'
import { PaymentsPlugin } from '~/store/modules/payments/interfaces'
import { WidgetsPlugin } from '~/store/modules/widgets/interfaces'
import { EmailTemplatesPlugin } from '~/store/modules/email-templates/interfaces'
import { SubscribersPlugin } from '~/store/modules/subscribers/interfaces'
import { ConfigsPlugin } from '~/store/modules/config/interfaces'
import { CurrenciesPlugin } from '~/store/modules/currencies/interfaces'
import { BannerBlocksPlugin } from '~/store/modules/banner-blocks/interface'
import { RolesPlugin } from '~/store/modules/roles/interfaces'
import { SitesPlugin } from '~/store/modules/sites/interfaces'
import { SeoPagesPlugin } from '~/store/modules/seo-pages/interfaces'
import { PriceRolesPlugin } from '~/store/modules/price-roles/interfaces'
import { PriceRoleAssignmentPlugin } from '~/store/modules/price-role-assignment/interfaces'
import { StickersPlugin } from '~/store/modules/stickers/interfaces'
import { ProductGroupsPlugin } from '~/store/modules/product-groups/interfaces'
import { ProductsPlugin } from '~/store/modules/products/interfaces'
import { OrdersPlugin } from '~/store/modules/orders/interfaces'
import { PartnersPlugin } from '~/store/modules/partners/interfaces'
import { DeliveryGroupsPlugin } from '~/store/modules/delivery-groups/interfaces'
import { ArchivePlugin } from '~/store/modules//archive/interfaces'
import { DeliveryPlugin } from '~/store/modules/delivery/interfaces'
import { WordReplacesPlugin } from '~/store/modules/word-replaces/interfaces'
import { WordPluralsPlugin } from '~/store/modules/word-plurals/interfaces'
import { WordCasesPlugin } from '~/store/modules/word-cases/interfaces'
import { WordAdjectivesPlugin } from '~/store/modules/word-adjectives/interfaces'
import { TemplatesPlugin } from '~/store/modules/templates/interfaces'
import { GeneratePlugin } from '~/store/modules/generate/interfaces'
import { DeliveryPricesPlugin } from '~/store/modules/delivery-prices/interfaces'
import { RobotsPlugin } from '~/store/modules/robots/interfaces'
import { CategoriesPlugin } from '~/store/modules/categories/interfaces'
import { FormsPlugin } from '~/store/modules/forms/interfaces'
import { FormParamsPlugin } from '~/store/modules/form-params/interfaces'
import { FilesPlugin } from '~/store/modules/files/interfaces'
import { OptionsValuesPlugin } from '~/store/modules/options-values/interfaces'
import { NewsPlugin } from '~/store/modules/news/interfaces'
import { ReviewsPlugin } from '~/store/modules/reviews/interfaces'
import { RegionsPlugin } from '~/store/modules/regions/interfaces'
import { PointsPlugin } from '~/store/modules/points/interfaces'
import { ListWaitingPlugin } from '~/store/modules/list-waiting/interfaces'
import { OrderStatusesPlugin } from '~/store/modules/order-statuses/interfaces'
import { OrderStatusGroupsPlugin } from '~/store/modules/order-status-groups/interfaces'
import { UsersPlugin } from '~/store/modules/users/interfaces'
import { MainPagePlugin } from '~/store/modules/main-page/interfaces'
import { ProductOffersPlugin } from '~/store/modules/product-offers/interfaces'
import { DiscountGroupsPlugin } from '~/store/modules/discount-groups/interfaces'
import { BasketsPlugin } from '~/store/modules/baskets/interfaces'
import { PagesPlugin } from '~/store/modules/pages/interfaces'
import { OrderBillsPlugin } from '~/store/modules/order-bills/interfaces'
import { OffersPlugin } from '~/store/modules/offer/interfaces'
import { OrderOffersPlugin } from '~/store/modules/order-offers/interfaces'
import { FixedProductsPlugin } from '~/store/modules/product-fixed/interfaces'
import { FormatPlugin } from '~/store/modules/format/interfaces'
import { PermissionsPlugin } from '~/store/modules/permissions/interfaces'
import { ReindexerPlugin } from '~/store/modules/reindexer/interfaces'
import { BonusPlugin } from '~/store/modules/bonus/interfaces'
import { BonusAccountPlugin } from '~/store/modules/bonus-account/interfaces'
import { BonusTypePlugin } from '~/store/modules/bonus-type/interfaces'
import { BonusLogPlugin } from '~/store/modules/bonus-log/interfaces'
import { ErrorLogsPlugin } from '~/store/modules/error-logs/interfaces'
import { SeoTextsPlugin } from '~/store/modules/seo-texts/interfaces'
import { FeedsPlugin } from '~/store/modules/feeds/interfaces'
import { PromoCodesPlugin } from '~/store/modules/promo-codes/interfaces'
import { CustomFiltersPlugin } from '~/store/modules/custom-filters/interfaces'
import { SearchTextReplacesPlugin } from '~/store/modules/search-text-replace/interfaces'
import { UserTypesPlugin } from '~/store/modules/users-type/interfaces'
import { DeliveryPoint, RequestParams } from '~/plugins/delivery-points'
import { OrderCommentsPlugin } from '~/store/modules/order-comments/interfaces'
import { SearchLogsPlugin } from '~/store/modules/search-logs/interfaces'
import { CollectionsPlugin } from '~/store/modules/collections/interfaces'
import { EmailTemplateGroupsPlugin } from '~/store/modules/email-template-groups/interfaces'
import { RafflesPlugin } from '~/store/modules/raffles/interface'
import { DownloadPlugin } from '~/plugins/download'
import { NewslettersPlugin } from '~/store/modules/newsletters/interfaces'
import { CurrencySourcePlugin } from '~/store/modules/currency-source/interface'

interface RootState {}

export interface BaseStoreModules {
  $calculatorElements: CalculatorElementsPlugin;
  $calculatorConditions: CalculatorConditionsPlugin;
  $calculators: CalculatorsPlugin;
  $calculatorTypes: CalculatorTypesPlugin;
  $pages: PagesPlugin;
  $pageTemplates: PageTemplatesPlugin;
}
interface StoreModules extends BaseStoreModules {
  $wait: VueWaitInstance;
  $auth: AuthPlugin;
  $payments: PaymentsPlugin;
  $widgets: WidgetsPlugin;
  $emailTemplates: EmailTemplatesPlugin;
  $subscribers: SubscribersPlugin;
  $configsList: ConfigsPlugin;
  $currencies: CurrenciesPlugin;
  $bannerBlocks: BannerBlocksPlugin;
  $roles: RolesPlugin;
  $sites: SitesPlugin;
  $seoPages: SeoPagesPlugin;
  $priceRoles: PriceRolesPlugin;
  $priceRoleAssignment: PriceRoleAssignmentPlugin;
  $stickers: StickersPlugin;
  $productGroups: ProductGroupsPlugin;
  $products: ProductsPlugin;
  $orders: OrdersPlugin;
  $partners: PartnersPlugin;
  $deliveryGroups: DeliveryGroupsPlugin;
  $archive: ArchivePlugin;
  $delivery: DeliveryPlugin;
  $wordReplaces: WordReplacesPlugin;
  $wordPlurals: WordPluralsPlugin;
  $wordCases: WordCasesPlugin;
  $wordAdjectives: WordAdjectivesPlugin;
  $templates: TemplatesPlugin;
  $generate: GeneratePlugin;
  $deliveryPrices: DeliveryPricesPlugin;
  $robots: RobotsPlugin;
  $categories: CategoriesPlugin;
  $forms: FormsPlugin;
  $formParams: FormParamsPlugin;
  $customFilters: CustomFiltersPlugin;
  $files: FilesPlugin;
  $optionsValues: OptionsValuesPlugin;
  $listWaiting: ListWaitingPlugin;
  $news: NewsPlugin;
  $reviews: ReviewsPlugin;
  $users: UsersPlugin;
  $orderStatuses: OrderStatusesPlugin;
  $orderStatusGroups: OrderStatusGroupsPlugin;
  $regions: RegionsPlugin;
  $points: PointsPlugin;
  $mainPage: MainPagePlugin;
  $productOffers: ProductOffersPlugin;
  $promoCodes: PromoCodesPlugin;
  $discountGroups: DiscountGroupsPlugin;
  $searchTextReplace: SearchTextReplacesPlugin;
  $baskets: BasketsPlugin;
  $orderBills: OrderBillsPlugin;
  $offers: OffersPlugin;
  $orderOffers: OrderOffersPlugin;
  $fixedProducts: FixedProductsPlugin;
  $permissions: PermissionsPlugin;
  $reindexer: ReindexerPlugin;
  $userTypes: UserTypesPlugin;
  $bonus: BonusPlugin;
  $bonusAccount: BonusAccountPlugin;
  $bonusType: BonusTypePlugin;
  $bonusLog: BonusLogPlugin;
  $errorLogs: ErrorLogsPlugin;
  $seoTexts: SeoTextsPlugin;
  $feeds: FeedsPlugin;
  $orderComments: OrderCommentsPlugin;
  $searchLogs: SearchLogsPlugin;
  $collections: CollectionsPlugin;
  $calculations: CalculationsPlugin;
  $emailTemplateGroups: EmailTemplateGroupsPlugin
  $raffles: RafflesPlugin
  $dadata: DadataPlugin
  $eventTriggers: EventTriggersPlugin
  $pageTemplates: PageTemplatesPlugin
  $newsletters: NewslettersPlugin
  $currencySource: CurrencySourcePlugin
}
interface PluginModules {
  $format: FormatPlugin
  $cookies: NuxtCookies
  $colorMode: ColorModeInstance
  $imageUrl (param: number, config?: any): string;
  // $download (data: Blob, options: {name: string, type: keyof typeof MimeTypes}): void
  $download: DownloadPlugin
  $deliveryPoints (requestParams: RequestParams): Promise<DeliveryPoint[]>
}
declare module '*.vue' {
  interface Vue extends StoreModules, PluginModules {
  }
}
declare module '@nuxt/types' {
  interface Context extends StoreModules, PluginModules {}
  interface NuxtAppOptions extends StoreModules, PluginModules {}
}
declare module 'vue/types/vue' {
  interface Vue extends StoreModules, PluginModules {
  }
  interface Element extends StoreModules, PluginModules {
  }

}

declare module 'vuex/types/index' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Store<S> extends StoreModules, PluginModules {}
}

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit (_, ctx: Context) {
    try {
      if (ctx.app.$auth.isAuth) {
        await ctx.app.$auth.getProfile()
      }
    } catch (e: any) {
      if (e.error_code === 401) {
        return ctx.app.$auth.logout()
      }
      ctx.error({ statusCode: e.error_code, message: e.error_message })
    }
  }
}
