import { PageParams, ValidatorParams, ResponseData } from '~/store/interfaces'

/**
 * Дни недели
 */
export enum Weekdays {
  monday = 'Понедельник',
  tuesday = 'Вторник',
  wednesday = 'Среда',
  thursday = 'Четверг',
  friday = 'Пятница',
  saturday = 'Суббота',
  sunday = 'Воскресенье'
}

export enum Lunch {
  lunch = 'Обед'
}

/**
 * Часы работы дня
 */
export interface BusinessHours {
  active: boolean,
  openingHours: string | null,
  closingHours: string | null
}

/**
 * Часы работы недели
 */
export interface BusinessHoursWeek extends Record<keyof typeof Weekdays | keyof typeof Lunch, BusinessHours> {}

/**
 * * Шаблон парметров point
 */
export interface Point {
  id?: number
  name: string
  zoom: number
  address: string
  images: number[]
  meta: MetaData
  regionsId?: number
  x?: number
  tags: string[]
  y?: number
  params?: Record<string, any>
}

/**
 * * Шаблон парметров metaShop
 */
export interface MetaData extends DillerData {
  time?: string
  phone?: string
  street?: string
  city?: string
  country?: string
  house?: string
  region?: string
  point?: string
  value?: string
  kladrId?: string
  zip?: string
  x?: string
  y?: string
  /**
   * Часы работы
   */
  businessHours?: BusinessHoursWeek
  /**
   * Объединение графиков дней
   */
  useMerge?: boolean
  /**
   * Типы карт
   */
  cardTypes?: string
}

export interface DillerData {
  dillerEmail?: string // данные дилера
  dillerLink?: string // данные дилера
  dillerName?: string // данные дилера
}

export interface Filters {
  regionsId?: number,
  dillerName?: string,
  name?: string,
  address?: string
}

/**
 * * Объект плагина
 */
export interface PointsPlugin {
  /**
   ** Filters
   */
  filters: Filters & DillerData
  /**
   * * Валидна ли форма
   */
  validators: ValidatorParams

  /**
   * * список point и paginator
   */
  pointsList: ResponseData<Point>

  /**
   * * текущий point
   */
  currentPoint: Point

  /**
   * Изменение свойств по ключам
   * @param value значение свойства
   * @param keys ключи свойства в порядке вложенности
   */
  setItemPropByKeys(
    value: any,
    ...keys: (string | number)[]
  ): void;

  /**
   * * Запрос на список Points
   */
  getPoints: (pageParams?: PageParams | null) => Promise<ResponseData<Point>>

  /**
   * * Поиск point в списоке PointsList по id
   */
  pointById: (id: number) => Point | undefined

  /**
   * * Обнулить форму point
   */
  resetCurrentPoint: () => void

  /**
   * * Обнулить форму point
   */
  resetPointsList: () => void

  /**
   * * Очистить фильтры
   */
  resetFilters: () => void

  /**
   * * Запрос point по id
   */
  getPoint: (id: number) => Promise<Point>

  /**
   * * Запрос на удаление point по id
   */
  removePoint: (id: number) => Promise<Point>

  /**
   * * Запрос на изменение point
   */
  editPoint: () => Promise<Point>

  /**
   * * Запрос на создание point
   */
  createPoint: () => Promise<Point>

}
